import { FC, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Tooltip,
} from '@chakra-ui/react';
import { CreativeModalHeader } from '@apps/attention360/pages/strategyReports/CreativeModalHeader';
import { Dash } from '@apps/attention360/atoms';
import { useCustomToast } from '@hooks/toast';
import { useParams } from 'react-router-dom';
import { useCreateCreative } from '@hooks/creatives';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    AddCreativeValidationSchema,
    BrandedMomentType,
    MAX_CREATIVE_DURATION,
    MIN_CREATIVE_DURATION,
} from '@apps/attention360/pages/strategyReports/StrategyReportUtil';
import { InfoOutlineIcon } from '@chakra-ui/icons';

interface AddCreativeFormData {
    name: string;
}
type StrategyReportCreativeModalProps = {
    onClose: () => void;
    isOpen: boolean;
    onSuccess: (creativeId: string) => void;
};

export const AddCreativeModal: FC<StrategyReportCreativeModalProps> = ({
    onClose,
    isOpen,
    onSuccess,
}) => {
    const { strategyReportId } = useParams();
    const { successToast, errorToast } = useCustomToast();
    const [duration, setDuration] = useState(0);
    const [brandedMoments, setBrandedMoments] = useState<BrandedMomentType[]>([]);
    useEffect(() => {
        setBrandedMoments(
            Array.from({ length: duration }, (_, index) => ({
                index,
                isChecked: false,
                key: `moment_${index}`,
                start: index,
                end: index + 1,
            })),
        );
    }, [duration]);
    const toggleBrandedMoment = (index: number) => {
        setBrandedMoments((prev) =>
            prev.map((m) => (m.index === index ? { ...m, isChecked: !m.isChecked } : { ...m })),
        );
    };
    const { createCreative } = useCreateCreative();
    const resetModalState = () => {
        setDuration(0);
        setBrandedMoments([]);
    };
    const addCreative: SubmitHandler<AddCreativeFormData> = async (formData) => {
        try {
            const res = await createCreative({
                strategyReportId: strategyReportId ?? '',
                createCreativeRequestDto: {
                    name: formData.name,
                    duration,
                    brandedMoments: brandedMoments
                        .filter((b) => b.isChecked)
                        .map((b) => ({ start: b.start, end: b.end })),
                },
            });
            successToast('Creative has been added', '');
            resetModalState();
            onSuccess(res.id ?? '');
            onClose();
        } catch (e: any) {
            errorToast('Oops', 'Something went wrong when adding creative');
        }
    };
    const {
        handleSubmit,
        register,
        watch,
        formState: { errors: formErrors, isSubmitting: isFormSubmitting },
    } = useForm<AddCreativeFormData>({
        resolver: yupResolver(AddCreativeValidationSchema),
        mode: 'onChange',
    });
    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered size="3xl">
            <ModalOverlay />
            <ModalContent minWidth="24rem">
                <ModalCloseButton />
                <form onSubmit={handleSubmit(addCreative)}>
                    <ModalBody p="2rem">
                        <CreativeModalHeader
                            title="Add creative"
                            description="Add a creative and mark branded moments to measure the creative performance across media formats."
                        />
                        <Flex flexDirection="column" gap="1.25rem">
                            <FormControl isRequired isInvalid={formErrors.name !== undefined}>
                                {/** Name */}
                                <FormLabel>Name</FormLabel>
                                <Input type="text" {...register('name')} />
                                <FormErrorMessage>
                                    {formErrors.name && (formErrors.name.message as string)}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired>
                                {/** Duration in seconds */}
                                <FormLabel>Duration (seconds)</FormLabel>
                                <NumberInput
                                    value={duration}
                                    min={MIN_CREATIVE_DURATION}
                                    max={MAX_CREATIVE_DURATION}
                                    step={1}
                                    allowMouseWheel
                                    onChange={(valueString, valueAsNumber) => {
                                        if (
                                            valueString.length === 0 ||
                                            Number.isNaN(valueAsNumber)
                                        ) {
                                            setDuration(0);
                                            return;
                                        }
                                        let coerced = valueAsNumber;
                                        coerced =
                                            coerced > MAX_CREATIVE_DURATION
                                                ? MAX_CREATIVE_DURATION
                                                : coerced;
                                        coerced =
                                            coerced < MIN_CREATIVE_DURATION
                                                ? MIN_CREATIVE_DURATION
                                                : coerced;
                                        setDuration(coerced);
                                    }}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                <FormHelperText>
                                    Duration should be 0 if it’s a display creative.
                                </FormHelperText>
                            </FormControl>
                            <FormControl position="relative">
                                <Flex alignItems="center" gap="1rem">
                                    <FormLabel mr={0}>Branded moments</FormLabel>
                                    <Tooltip label="When your distinctive brand assets are used in the creative, including distinguishable logo, slogan, packaging, etc.">
                                        <Icon
                                            as={InfoOutlineIcon}
                                            boxSize="1rem"
                                            transform="translate(0,-2px)"
                                        />
                                    </Tooltip>
                                </Flex>
                                <Flex gap=".25rem" overflowX="auto">
                                    {brandedMoments.map((item) => (
                                        <Dash
                                            isChecked={item.isChecked}
                                            onChange={() => toggleBrandedMoment(item.index)}
                                            second={item.index + 1}
                                            key={item.key}
                                            totalSeconds={brandedMoments.length}
                                        />
                                    ))}
                                </Flex>
                                <FormHelperText>
                                    {duration > 0
                                        ? 'Attention on branded moments increases brand recognition and mental availability. attentionPLAN assumes that branding is always visible on a display creative.'
                                        : 'attentionPLAN assumes that branding is always visible on a display creative'}
                                </FormHelperText>
                            </FormControl>
                        </Flex>
                    </ModalBody>
                    <ModalFooter gap="1rem" alignItems="center" justifyContent="end" display="flex">
                        <Button colorScheme="gray" variant="outline" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            isDisabled={isFormSubmitting}
                            isLoading={isFormSubmitting}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};
