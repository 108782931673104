/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { FormatBenchmarkResponseDto } from './FormatBenchmarkResponseDto';
import {
    FormatBenchmarkResponseDtoFromJSON,
    FormatBenchmarkResponseDtoFromJSONTyped,
    FormatBenchmarkResponseDtoToJSON,
} from './FormatBenchmarkResponseDto';

/**
 *
 * @export
 * @interface ChannelBenchmarkResponseDto
 */
export interface ChannelBenchmarkResponseDto {
    /**
     *
     * @type {Array<FormatBenchmarkResponseDto>}
     * @memberof ChannelBenchmarkResponseDto
     */
    formatBenchmarkResponseDtos?: Array<FormatBenchmarkResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof ChannelBenchmarkResponseDto
     */
    channelId?: string;
    /**
     *
     * @type {string}
     * @memberof ChannelBenchmarkResponseDto
     */
    channelName?: string | null;
    /**
     *
     * @type {string}
     * @memberof ChannelBenchmarkResponseDto
     */
    channelCode?: string | null;
    /**
     *
     * @type {number}
     * @memberof ChannelBenchmarkResponseDto
     */
    averageActiveAttention?: number;
    /**
     *
     * @type {number}
     * @memberof ChannelBenchmarkResponseDto
     */
    averagePassiveAttention?: number;
    /**
     *
     * @type {number}
     * @memberof ChannelBenchmarkResponseDto
     */
    averageInactiveAttention?: number;
    /**
     *
     * @type {number}
     * @memberof ChannelBenchmarkResponseDto
     */
    cpm?: number;
    /**
     *
     * @type {boolean}
     * @memberof ChannelBenchmarkResponseDto
     */
    isSelected?: boolean;
}

/**
 * Check if a given object implements the ChannelBenchmarkResponseDto interface.
 */
export function instanceOfChannelBenchmarkResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelBenchmarkResponseDtoFromJSON(json: any): ChannelBenchmarkResponseDto {
    return ChannelBenchmarkResponseDtoFromJSONTyped(json, false);
}

export function ChannelBenchmarkResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ChannelBenchmarkResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        formatBenchmarkResponseDtos: !exists(json, 'format_benchmark_response_dtos')
            ? undefined
            : json['format_benchmark_response_dtos'] === null
            ? null
            : (json['format_benchmark_response_dtos'] as Array<any>).map(
                  FormatBenchmarkResponseDtoFromJSON,
              ),
        channelId: !exists(json, 'channel_id') ? undefined : json['channel_id'],
        channelName: !exists(json, 'channel_name') ? undefined : json['channel_name'],
        channelCode: !exists(json, 'channel_code') ? undefined : json['channel_code'],
        averageActiveAttention: !exists(json, 'average_active_attention')
            ? undefined
            : json['average_active_attention'],
        averagePassiveAttention: !exists(json, 'average_passive_attention')
            ? undefined
            : json['average_passive_attention'],
        averageInactiveAttention: !exists(json, 'average_inactive_attention')
            ? undefined
            : json['average_inactive_attention'],
        cpm: !exists(json, 'cpm') ? undefined : json['cpm'],
        isSelected: !exists(json, 'is_selected') ? undefined : json['is_selected'],
    };
}

export function ChannelBenchmarkResponseDtoToJSON(value?: ChannelBenchmarkResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        format_benchmark_response_dtos:
            value.formatBenchmarkResponseDtos === undefined
                ? undefined
                : value.formatBenchmarkResponseDtos === null
                ? null
                : (value.formatBenchmarkResponseDtos as Array<any>).map(
                      FormatBenchmarkResponseDtoToJSON,
                  ),
        channel_id: value.channelId,
        channel_name: value.channelName,
        channel_code: value.channelCode,
        average_active_attention: value.averageActiveAttention,
        average_passive_attention: value.averagePassiveAttention,
        average_inactive_attention: value.averageInactiveAttention,
        cpm: value.cpm,
        is_selected: value.isSelected,
    };
}
