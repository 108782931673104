import { SelectedAdFormatRequestDto } from '@api-clients/attention-data';
import { SelectedAdFormatResponseDto } from '@api-clients/attention-data/models/SelectedAdFormatResponseDto';
import { Button, Icon, useBoolean, useDisclosure, useToast } from '@chakra-ui/react';
import { AdFormatsReportsModal } from '@components/molecules/modals/adFormatsReportsModal/AdFormatsReportsModal';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { useUpdateAdFormats } from '@hooks/strategyReport/useUpdateAdFormats';
import { useGetStrategyReports } from '@hooks/strategyReports';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useStrategyReportContext } from './StrategyReportContextProvider';

export const ConfigureAdFormatsButton: FC = () => {
    const { strategyReport, setStrategyReport } = useStrategyReportContext();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { updateAdFormats } = useUpdateAdFormats();
    const { strategyReportId } = useParams();
    const [isLoading, setIsLoading] = useBoolean(false);

    const { getStrategyReports } = useGetStrategyReports();
    const toast = useToast();

    return (
        <>
            <Button
                variant="outline"
                isLoading={!strategyReport.selectedFormats}
                onClick={onOpen}
                leftIcon={<Icon as={AdjustmentsHorizontalIcon} />}
                colorScheme="orange"
                whiteSpace="nowrap"
            >
                Configure formats
            </Button>

            {strategyReport.selectedFormats && (
                <AdFormatsReportsModal
                    selectedAdFormats={strategyReport.selectedFormats}
                    onConfirm={(formats) => {
                        setIsLoading.on();
                        updateAdFormats(strategyReportId!, {
                            selectedFormatCodes: formats.map(
                                (f): SelectedAdFormatRequestDto => ({
                                    adFormatCode: f.adFormatCode,
                                    cpm: f.cpm,
                                    isChecked: f.isChecked,
                                }),
                            ),
                        }).then(() => {
                            getStrategyReports({ strategyReportId: strategyReportId! })
                                .then((response) => setStrategyReport(response))
                                .finally(() => {
                                    setIsLoading.off();
                                    onClose();
                                    toast({
                                        title: `Ad formats updated`,
                                        description: `The strategy report has been updated with the selected formats`,
                                        status: 'success',
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                });
                        });
                    }}
                    isLoading={isLoading}
                    onCancel={onClose}
                    isOpen={isOpen}
                />
            )}
        </>
    );
};
