import { CountryCodeIso2 } from '@api-clients/shared';
import { FeatureBalanceResponseDto, FeatureCode, LimitType } from '@api-clients/subscriptions';

export const AllCountryFeatureCodes = [
    FeatureCode.MediaPlansAccessCountryAe,
    FeatureCode.MediaPlansAccessCountryAu,
    FeatureCode.MediaPlansAccessCountryBe,
    FeatureCode.MediaPlansAccessCountryCa,
    FeatureCode.MediaPlansAccessCountryDe,
    FeatureCode.MediaPlansAccessCountryFr,
    FeatureCode.MediaPlansAccessCountryIe,
    FeatureCode.MediaPlansAccessCountryMx,
    FeatureCode.MediaPlansAccessCountryNz,
    FeatureCode.MediaPlansAccessCountrySa,
    FeatureCode.MediaPlansAccessCountryGb,
    FeatureCode.MediaPlansAccessCountryUs,
    FeatureCode.AttentionDataAccessCountryAe,
    FeatureCode.AttentionDataAccessCountryAu,
    FeatureCode.AttentionDataAccessCountryBe,
    FeatureCode.AttentionDataAccessCountryCa,
    FeatureCode.AttentionDataAccessCountryDe,
    FeatureCode.AttentionDataAccessCountryFr,
    FeatureCode.AttentionDataAccessCountryIe,
    FeatureCode.AttentionDataAccessCountryMx,
    FeatureCode.AttentionDataAccessCountryNz,
    FeatureCode.AttentionDataAccessCountryPl,
    FeatureCode.AttentionDataAccessCountrySa,
    FeatureCode.AttentionDataAccessCountryGb,
    FeatureCode.AttentionDataAccessCountryUs,
    FeatureCode.AttentionDataAccessCountryGlobal,
    FeatureCode.AarcAccessCountryAe,
    FeatureCode.AarcAccessCountryAu,
    FeatureCode.AarcAccessCountryBe,
    FeatureCode.AarcAccessCountryCa,
    FeatureCode.AarcAccessCountryDe,
    FeatureCode.AarcAccessCountryFr,
    FeatureCode.AarcAccessCountryIe,
    FeatureCode.AarcAccessCountryMx,
    FeatureCode.AarcAccessCountryNz,
    FeatureCode.AarcAccessCountrySa,
    FeatureCode.AarcAccessCountryGb,
    FeatureCode.AarcAccessCountryUs,
    FeatureCode.AarcAccessCountryGlobal,
    FeatureCode.StrategyReportAccessCountryAe,
    FeatureCode.StrategyReportAccessCountryAu,
    FeatureCode.StrategyReportAccessCountryBe,
    FeatureCode.StrategyReportAccessCountryCa,
    FeatureCode.StrategyReportAccessCountryDe,
    FeatureCode.StrategyReportAccessCountryFr,
    FeatureCode.StrategyReportAccessCountryIe,
    FeatureCode.StrategyReportAccessCountryMx,
    FeatureCode.StrategyReportAccessCountryNz,
    FeatureCode.StrategyReportAccessCountryPl,
    FeatureCode.StrategyReportAccessCountrySa,
    FeatureCode.StrategyReportAccessCountryGb,
    FeatureCode.StrategyReportAccessCountryUs,
    FeatureCode.StrategyReportAccessCountryGlobal,
] as const;

export type CountryFeatureCode = typeof AllCountryFeatureCodes[number];

export function isCountryFeatureCode(value: string): value is CountryFeatureCode {
    return (AllCountryFeatureCodes as unknown as Array<string>).includes(value);
}

export const GetCountryNameFromCountryFeature = (countryFeatureCode: CountryFeatureCode) => {
    switch (countryFeatureCode) {
        case FeatureCode.MediaPlansAccessCountryAe:
        case FeatureCode.AttentionDataAccessCountryAe:
        case FeatureCode.AarcAccessCountryAe:
        case FeatureCode.StrategyReportAccessCountryAe:
            return 'United Arab Emirates';
        case FeatureCode.MediaPlansAccessCountryAu:
        case FeatureCode.AttentionDataAccessCountryAu:
        case FeatureCode.AarcAccessCountryAu:
        case FeatureCode.StrategyReportAccessCountryAu:
            return 'Australia';
        case FeatureCode.MediaPlansAccessCountryBe:
        case FeatureCode.AttentionDataAccessCountryBe:
        case FeatureCode.AarcAccessCountryBe:
        case FeatureCode.StrategyReportAccessCountryBe:
            return 'Belgium';
        case FeatureCode.MediaPlansAccessCountryCa:
        case FeatureCode.AttentionDataAccessCountryCa:
        case FeatureCode.AarcAccessCountryCa:
        case FeatureCode.StrategyReportAccessCountryCa:
            return 'Canada';
        case FeatureCode.MediaPlansAccessCountryDe:
        case FeatureCode.AttentionDataAccessCountryDe:
        case FeatureCode.AarcAccessCountryDe:
        case FeatureCode.StrategyReportAccessCountryDe:
            return 'Germany';
        case FeatureCode.MediaPlansAccessCountryFr:
        case FeatureCode.AttentionDataAccessCountryFr:
        case FeatureCode.AarcAccessCountryFr:
        case FeatureCode.StrategyReportAccessCountryFr:
            return 'France';
        case FeatureCode.MediaPlansAccessCountryIe:
        case FeatureCode.AttentionDataAccessCountryIe:
        case FeatureCode.AarcAccessCountryIe:
        case FeatureCode.StrategyReportAccessCountryIe:
            return 'Ireland';
        case FeatureCode.MediaPlansAccessCountryMx:
        case FeatureCode.AttentionDataAccessCountryMx:
        case FeatureCode.AarcAccessCountryMx:
        case FeatureCode.StrategyReportAccessCountryMx:
            return 'Mexico';
        case FeatureCode.MediaPlansAccessCountryNz:
        case FeatureCode.AttentionDataAccessCountryNz:
        case FeatureCode.AarcAccessCountryNz:
        case FeatureCode.StrategyReportAccessCountryNz:
            return 'New Zealand';
        case FeatureCode.MediaPlansAccessCountrySa:
        case FeatureCode.AttentionDataAccessCountrySa:
        case FeatureCode.AarcAccessCountrySa:
        case FeatureCode.StrategyReportAccessCountrySa:
            return 'Saudi Arabia';
        case FeatureCode.MediaPlansAccessCountryGb:
        case FeatureCode.AttentionDataAccessCountryGb:
        case FeatureCode.AarcAccessCountryGb:
        case FeatureCode.StrategyReportAccessCountryGb:
            return 'Great Britain';
        case FeatureCode.MediaPlansAccessCountryUs:
        case FeatureCode.AttentionDataAccessCountryUs:
        case FeatureCode.AarcAccessCountryUs:
        case FeatureCode.StrategyReportAccessCountryUs:
            return 'USA';
        case FeatureCode.AttentionDataAccessCountryPl:
        case FeatureCode.StrategyReportAccessCountryPl:
            return 'Poland';
        default:
            return '';
    }
};

export const GetCountryCodeFromCountryFeature = (countryFeatureCode: CountryFeatureCode) => {
    switch (countryFeatureCode) {
        case FeatureCode.MediaPlansAccessCountryAe:
        case FeatureCode.AttentionDataAccessCountryAe:
        case FeatureCode.AarcAccessCountryAe:
        case FeatureCode.StrategyReportAccessCountryAe:
            return CountryCodeIso2.Ae;
        case FeatureCode.MediaPlansAccessCountryAu:
        case FeatureCode.AttentionDataAccessCountryAu:
        case FeatureCode.AarcAccessCountryAu:
        case FeatureCode.StrategyReportAccessCountryAu:
            return CountryCodeIso2.Au;
        case FeatureCode.MediaPlansAccessCountryBe:
        case FeatureCode.AttentionDataAccessCountryBe:
        case FeatureCode.AarcAccessCountryBe:
        case FeatureCode.StrategyReportAccessCountryBe:
            return CountryCodeIso2.Be;
        case FeatureCode.MediaPlansAccessCountryCa:
        case FeatureCode.AttentionDataAccessCountryCa:
        case FeatureCode.AarcAccessCountryCa:
        case FeatureCode.StrategyReportAccessCountryCa:
            return CountryCodeIso2.Ca;
        case FeatureCode.MediaPlansAccessCountryDe:
        case FeatureCode.AttentionDataAccessCountryDe:
        case FeatureCode.AarcAccessCountryDe:
        case FeatureCode.StrategyReportAccessCountryDe:
            return CountryCodeIso2.De;
        case FeatureCode.MediaPlansAccessCountryFr:
        case FeatureCode.AttentionDataAccessCountryFr:
        case FeatureCode.AarcAccessCountryFr:
        case FeatureCode.StrategyReportAccessCountryFr:
            return CountryCodeIso2.Fr;
        case FeatureCode.MediaPlansAccessCountryIe:
        case FeatureCode.AttentionDataAccessCountryIe:
        case FeatureCode.AarcAccessCountryIe:
        case FeatureCode.StrategyReportAccessCountryIe:
            return CountryCodeIso2.Ie;
        case FeatureCode.MediaPlansAccessCountryMx:
        case FeatureCode.AttentionDataAccessCountryMx:
        case FeatureCode.AarcAccessCountryMx:
        case FeatureCode.StrategyReportAccessCountryMx:
            return CountryCodeIso2.Mx;
        case FeatureCode.MediaPlansAccessCountryNz:
        case FeatureCode.AttentionDataAccessCountryNz:
        case FeatureCode.AarcAccessCountryNz:
        case FeatureCode.StrategyReportAccessCountryNz:
            return CountryCodeIso2.Nz;
        case FeatureCode.AttentionDataAccessCountryPl:
        case FeatureCode.StrategyReportAccessCountryPl:
            return CountryCodeIso2.Pl;
        case FeatureCode.MediaPlansAccessCountrySa:
        case FeatureCode.AttentionDataAccessCountrySa:
        case FeatureCode.AarcAccessCountrySa:
        case FeatureCode.StrategyReportAccessCountrySa:
            return CountryCodeIso2.Sa;
        case FeatureCode.MediaPlansAccessCountryGb:
        case FeatureCode.AttentionDataAccessCountryGb:
        case FeatureCode.AarcAccessCountryGb:
        case FeatureCode.StrategyReportAccessCountryGb:
            return CountryCodeIso2.Gb;
        case FeatureCode.MediaPlansAccessCountryUs:
        case FeatureCode.AttentionDataAccessCountryUs:
        case FeatureCode.AarcAccessCountryUs:
        case FeatureCode.StrategyReportAccessCountryUs:
            return CountryCodeIso2.Us;
        default:
            return '';
    }
};

const isApiFeature = (featureCode: FeatureCode): boolean => {
    return (
        featureCode === FeatureCode.AttentionDataApiAccess ||
        featureCode === FeatureCode.AarcApiAccess ||
        featureCode === FeatureCode.ProveApiAccess
    );
};

export const shouldDisplayApiApplications = (featureBalances: FeatureBalanceResponseDto[]) => {
    return featureBalances.some(
        (f) => f.featureCode && isApiFeature(f.featureCode) && f.limitType === LimitType.Unlimited,
    );
};

const notIncludeGlobal = (str: string) => !str.includes('global');

export const isAttentionDataCountryFeature = (featureCode: FeatureCode) => {
    const toLower = featureCode.toLowerCase();
    return (
        toLower.includes('attentiondata') &&
        notIncludeGlobal(toLower) &&
        isCountryFeatureCode(featureCode)
    );
};

export const isMediaPlanCountryFeature = (featureCode: FeatureCode) => {
    const toLower = featureCode.toLowerCase();
    return (
        toLower.includes('mediaplans') &&
        notIncludeGlobal(toLower) &&
        isCountryFeatureCode(featureCode)
    );
};

export const isStrategyReportCountryFeature = (featureCode: FeatureCode) => {
    const toLower = featureCode.toLowerCase();
    return (
        toLower.includes('strategy') &&
        notIncludeGlobal(toLower) &&
        isCountryFeatureCode(featureCode)
    );
};

export const isAarcCountryFeature = (featureCode: FeatureCode) => {
    const toLower = featureCode.toLowerCase();
    return (
        toLower.includes('aarc') && notIncludeGlobal(toLower) && isCountryFeatureCode(featureCode)
    );
};
