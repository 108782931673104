/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../../runtime';
import type {
    AudienceAnalysisChannelBenchmarkResponseDto,
    ChannelBenchmarkResponseDto,
    ChannelSegmentResponseDto,
    CreateCreativeRequestDto,
    CreateStrategyReportRequestDto,
    CreativeResponseDto,
    DecaySecondResponseDto,
    FormatDecayResponseDto,
    ProblemDetails,
    StrategyReportBasicInfoResponseDto,
    StrategyReportResponseDto,
    UpdateAdFormatsRequestDto,
    UpdateCreativeRequestDto,
} from '../models';
import {
    AudienceAnalysisChannelBenchmarkResponseDtoFromJSON,
    AudienceAnalysisChannelBenchmarkResponseDtoToJSON,
    ChannelBenchmarkResponseDtoFromJSON,
    ChannelBenchmarkResponseDtoToJSON,
    ChannelSegmentResponseDtoFromJSON,
    ChannelSegmentResponseDtoToJSON,
    CreateCreativeRequestDtoFromJSON,
    CreateCreativeRequestDtoToJSON,
    CreateStrategyReportRequestDtoFromJSON,
    CreateStrategyReportRequestDtoToJSON,
    CreativeResponseDtoFromJSON,
    CreativeResponseDtoToJSON,
    DecaySecondResponseDtoFromJSON,
    DecaySecondResponseDtoToJSON,
    FormatDecayResponseDtoFromJSON,
    FormatDecayResponseDtoToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    StrategyReportBasicInfoResponseDtoFromJSON,
    StrategyReportBasicInfoResponseDtoToJSON,
    StrategyReportResponseDtoFromJSON,
    StrategyReportResponseDtoToJSON,
    UpdateAdFormatsRequestDtoFromJSON,
    UpdateAdFormatsRequestDtoToJSON,
    UpdateCreativeRequestDtoFromJSON,
    UpdateCreativeRequestDtoToJSON,
} from '../models';

export interface CreateCreativeRequest {
    strategyReportId: string;
    createCreativeRequestDto?: CreateCreativeRequestDto;
}

export interface CreateStrategyReportRequest {
    createStrategyReportRequestDto?: CreateStrategyReportRequestDto;
}

export interface DeleteCreativeRequest {
    strategyReportId: string;
    creativeId: string;
}

export interface DeleteStrategyReportRequest {
    strategyReportId: string;
}

export interface GetAllBenchmarkResultsRequest {
    strategyReportId: string;
}

export interface GetAllBenchmarkResultsForAudienceAnalysisRequest {
    strategyReportId: string;
}

export interface GetAllCreativesForAStrategyReportRequest {
    strategyReportId: string;
}

export interface GetAllDecaySecondsForAFormatRequest {
    strategyReportId: string;
    formatCode: string;
}

export interface GetAllSegmentsRequest {
    strategyReportId: string;
}

export interface GetCreativeRequest {
    strategyReportId: string;
    creativeId: string;
}

export interface GetFormatDecayForAllFormatsRequest {
    strategyReportId: string;
}

export interface GetStrategyReportRequest {
    strategyReportId: string;
}

export interface UpdateCreativeRequest {
    strategyReportId: string;
    creativeId: string;
    updateCreativeRequestDto?: UpdateCreativeRequestDto;
}

export interface UpdateTheAdFormatsForStrategyReportRequest {
    strategyReportId: string;
    updateAdFormatsRequestDto?: UpdateAdFormatsRequestDto;
}

/**
 * StrategyReportsApi - interface
 *
 * @export
 * @interface StrategyReportsApiInterface
 */
export interface StrategyReportsApiInterface {
    /**
     *
     * @param {string} strategyReportId
     * @param {CreateCreativeRequestDto} [createCreativeRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    createCreativeRaw(
        requestParameters: CreateCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CreativeResponseDto>>;

    /**
     */
    createCreative(
        requestParameters: CreateCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CreativeResponseDto>;

    /**
     *
     * @param {CreateStrategyReportRequestDto} [createStrategyReportRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    createStrategyReportRaw(
        requestParameters: CreateStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<StrategyReportResponseDto>>;

    /**
     */
    createStrategyReport(
        requestParameters: CreateStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<StrategyReportResponseDto>;

    /**
     *
     * @param {string} strategyReportId
     * @param {string} creativeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    deleteCreativeRaw(
        requestParameters: DeleteCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>>;

    /**
     */
    deleteCreative(
        requestParameters: DeleteCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void>;

    /**
     *
     * @param {string} strategyReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    deleteStrategyReportRaw(
        requestParameters: DeleteStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<number>>;

    /**
     */
    deleteStrategyReport(
        requestParameters: DeleteStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<number>;

    /**
     *
     * @param {string} strategyReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    getAllBenchmarkResultsRaw(
        requestParameters: GetAllBenchmarkResultsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelBenchmarkResponseDto>>>;

    /**
     */
    getAllBenchmarkResults(
        requestParameters: GetAllBenchmarkResultsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelBenchmarkResponseDto>>;

    /**
     *
     * @param {string} strategyReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    getAllBenchmarkResultsForAudienceAnalysisRaw(
        requestParameters: GetAllBenchmarkResultsForAudienceAnalysisRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<AudienceAnalysisChannelBenchmarkResponseDto>>>;

    /**
     */
    getAllBenchmarkResultsForAudienceAnalysis(
        requestParameters: GetAllBenchmarkResultsForAudienceAnalysisRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AudienceAnalysisChannelBenchmarkResponseDto>>;

    /**
     *
     * @param {string} strategyReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    getAllCreativesForAStrategyReportRaw(
        requestParameters: GetAllCreativesForAStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CreativeResponseDto>>>;

    /**
     */
    getAllCreativesForAStrategyReport(
        requestParameters: GetAllCreativesForAStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CreativeResponseDto>>;

    /**
     *
     * @param {string} strategyReportId
     * @param {string} formatCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    getAllDecaySecondsForAFormatRaw(
        requestParameters: GetAllDecaySecondsForAFormatRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<DecaySecondResponseDto>>>;

    /**
     */
    getAllDecaySecondsForAFormat(
        requestParameters: GetAllDecaySecondsForAFormatRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<DecaySecondResponseDto>>;

    /**
     *
     * @param {string} strategyReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    getAllSegmentsRaw(
        requestParameters: GetAllSegmentsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelSegmentResponseDto>>>;

    /**
     */
    getAllSegments(
        requestParameters: GetAllSegmentsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelSegmentResponseDto>>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    getAllStrategyReportsRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<StrategyReportBasicInfoResponseDto>>>;

    /**
     */
    getAllStrategyReports(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<StrategyReportBasicInfoResponseDto>>;

    /**
     *
     * @param {string} strategyReportId
     * @param {string} creativeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    getCreativeRaw(
        requestParameters: GetCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CreativeResponseDto>>;

    /**
     */
    getCreative(
        requestParameters: GetCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CreativeResponseDto>;

    /**
     *
     * @param {string} strategyReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    getFormatDecayForAllFormatsRaw(
        requestParameters: GetFormatDecayForAllFormatsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<FormatDecayResponseDto>>>;

    /**
     */
    getFormatDecayForAllFormats(
        requestParameters: GetFormatDecayForAllFormatsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<FormatDecayResponseDto>>;

    /**
     *
     * @param {string} strategyReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    getStrategyReportRaw(
        requestParameters: GetStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<StrategyReportResponseDto>>;

    /**
     */
    getStrategyReport(
        requestParameters: GetStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<StrategyReportResponseDto>;

    /**
     *
     * @param {string} strategyReportId
     * @param {string} creativeId
     * @param {UpdateCreativeRequestDto} [updateCreativeRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    updateCreativeRaw(
        requestParameters: UpdateCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CreativeResponseDto>>;

    /**
     */
    updateCreative(
        requestParameters: UpdateCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CreativeResponseDto>;

    /**
     *
     * @param {string} strategyReportId
     * @param {UpdateAdFormatsRequestDto} [updateAdFormatsRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategyReportsApiInterface
     */
    updateTheAdFormatsForStrategyReportRaw(
        requestParameters: UpdateTheAdFormatsForStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<StrategyReportResponseDto>>;

    /**
     */
    updateTheAdFormatsForStrategyReport(
        requestParameters: UpdateTheAdFormatsForStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<StrategyReportResponseDto>;
}

/**
 *
 */
export class StrategyReportsApi extends runtime.BaseAPI implements StrategyReportsApiInterface {
    /**
     */
    async createCreativeRaw(
        requestParameters: CreateCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CreativeResponseDto>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling createCreative.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}/creatives`.replace(
                    `{${'strategyReportId'}}`,
                    encodeURIComponent(String(requestParameters.strategyReportId)),
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateCreativeRequestDtoToJSON(requestParameters.createCreativeRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CreativeResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async createCreative(
        requestParameters: CreateCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CreativeResponseDto> {
        const response = await this.createCreativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createStrategyReportRaw(
        requestParameters: CreateStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<StrategyReportResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/plan/strategy-reports`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateStrategyReportRequestDtoToJSON(
                    requestParameters.createStrategyReportRequestDto,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            StrategyReportResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async createStrategyReport(
        requestParameters: CreateStrategyReportRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<StrategyReportResponseDto> {
        const response = await this.createStrategyReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCreativeRaw(
        requestParameters: DeleteCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling deleteCreative.',
            );
        }

        if (requestParameters.creativeId === null || requestParameters.creativeId === undefined) {
            throw new runtime.RequiredError(
                'creativeId',
                'Required parameter requestParameters.creativeId was null or undefined when calling deleteCreative.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}/creatives/{creativeId}`
                    .replace(
                        `{${'strategyReportId'}}`,
                        encodeURIComponent(String(requestParameters.strategyReportId)),
                    )
                    .replace(
                        `{${'creativeId'}}`,
                        encodeURIComponent(String(requestParameters.creativeId)),
                    ),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCreative(
        requestParameters: DeleteCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.deleteCreativeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteStrategyReportRaw(
        requestParameters: DeleteStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<number>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling deleteStrategyReport.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}`.replace(
                    `{${'strategyReportId'}}`,
                    encodeURIComponent(String(requestParameters.strategyReportId)),
                ),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteStrategyReport(
        requestParameters: DeleteStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<number> {
        const response = await this.deleteStrategyReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllBenchmarkResultsRaw(
        requestParameters: GetAllBenchmarkResultsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelBenchmarkResponseDto>>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling getAllBenchmarkResults.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}/benchmarks/channels`.replace(
                    `{${'strategyReportId'}}`,
                    encodeURIComponent(String(requestParameters.strategyReportId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(ChannelBenchmarkResponseDtoFromJSON),
        );
    }

    /**
     */
    async getAllBenchmarkResults(
        requestParameters: GetAllBenchmarkResultsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelBenchmarkResponseDto>> {
        const response = await this.getAllBenchmarkResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllBenchmarkResultsForAudienceAnalysisRaw(
        requestParameters: GetAllBenchmarkResultsForAudienceAnalysisRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<AudienceAnalysisChannelBenchmarkResponseDto>>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling getAllBenchmarkResultsForAudienceAnalysis.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}/benchmarks/channels/audience-analysis`.replace(
                    `{${'strategyReportId'}}`,
                    encodeURIComponent(String(requestParameters.strategyReportId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(AudienceAnalysisChannelBenchmarkResponseDtoFromJSON),
        );
    }

    /**
     */
    async getAllBenchmarkResultsForAudienceAnalysis(
        requestParameters: GetAllBenchmarkResultsForAudienceAnalysisRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AudienceAnalysisChannelBenchmarkResponseDto>> {
        const response = await this.getAllBenchmarkResultsForAudienceAnalysisRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getAllCreativesForAStrategyReportRaw(
        requestParameters: GetAllCreativesForAStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CreativeResponseDto>>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling getAllCreativesForAStrategyReport.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}/creatives`.replace(
                    `{${'strategyReportId'}}`,
                    encodeURIComponent(String(requestParameters.strategyReportId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(CreativeResponseDtoFromJSON),
        );
    }

    /**
     */
    async getAllCreativesForAStrategyReport(
        requestParameters: GetAllCreativesForAStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CreativeResponseDto>> {
        const response = await this.getAllCreativesForAStrategyReportRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getAllDecaySecondsForAFormatRaw(
        requestParameters: GetAllDecaySecondsForAFormatRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<DecaySecondResponseDto>>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling getAllDecaySecondsForAFormat.',
            );
        }

        if (requestParameters.formatCode === null || requestParameters.formatCode === undefined) {
            throw new runtime.RequiredError(
                'formatCode',
                'Required parameter requestParameters.formatCode was null or undefined when calling getAllDecaySecondsForAFormat.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}/decay/formats/{formatCode}`
                    .replace(
                        `{${'strategyReportId'}}`,
                        encodeURIComponent(String(requestParameters.strategyReportId)),
                    )
                    .replace(
                        `{${'formatCode'}}`,
                        encodeURIComponent(String(requestParameters.formatCode)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(DecaySecondResponseDtoFromJSON),
        );
    }

    /**
     */
    async getAllDecaySecondsForAFormat(
        requestParameters: GetAllDecaySecondsForAFormatRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<DecaySecondResponseDto>> {
        const response = await this.getAllDecaySecondsForAFormatRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getAllSegmentsRaw(
        requestParameters: GetAllSegmentsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChannelSegmentResponseDto>>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling getAllSegments.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}/segments/channels`.replace(
                    `{${'strategyReportId'}}`,
                    encodeURIComponent(String(requestParameters.strategyReportId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(ChannelSegmentResponseDtoFromJSON),
        );
    }

    /**
     */
    async getAllSegments(
        requestParameters: GetAllSegmentsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChannelSegmentResponseDto>> {
        const response = await this.getAllSegmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllStrategyReportsRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<StrategyReportBasicInfoResponseDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/strategy-reports/all`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(StrategyReportBasicInfoResponseDtoFromJSON),
        );
    }

    /**
     */
    async getAllStrategyReports(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<StrategyReportBasicInfoResponseDto>> {
        const response = await this.getAllStrategyReportsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCreativeRaw(
        requestParameters: GetCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CreativeResponseDto>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling getCreative.',
            );
        }

        if (requestParameters.creativeId === null || requestParameters.creativeId === undefined) {
            throw new runtime.RequiredError(
                'creativeId',
                'Required parameter requestParameters.creativeId was null or undefined when calling getCreative.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}/creatives/{creativeId}`
                    .replace(
                        `{${'strategyReportId'}}`,
                        encodeURIComponent(String(requestParameters.strategyReportId)),
                    )
                    .replace(
                        `{${'creativeId'}}`,
                        encodeURIComponent(String(requestParameters.creativeId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CreativeResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getCreative(
        requestParameters: GetCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CreativeResponseDto> {
        const response = await this.getCreativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFormatDecayForAllFormatsRaw(
        requestParameters: GetFormatDecayForAllFormatsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<FormatDecayResponseDto>>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling getFormatDecayForAllFormats.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}/decay/formats`.replace(
                    `{${'strategyReportId'}}`,
                    encodeURIComponent(String(requestParameters.strategyReportId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(FormatDecayResponseDtoFromJSON),
        );
    }

    /**
     */
    async getFormatDecayForAllFormats(
        requestParameters: GetFormatDecayForAllFormatsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<FormatDecayResponseDto>> {
        const response = await this.getFormatDecayForAllFormatsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getStrategyReportRaw(
        requestParameters: GetStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<StrategyReportResponseDto>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling getStrategyReport.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}`.replace(
                    `{${'strategyReportId'}}`,
                    encodeURIComponent(String(requestParameters.strategyReportId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            StrategyReportResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async getStrategyReport(
        requestParameters: GetStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<StrategyReportResponseDto> {
        const response = await this.getStrategyReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateCreativeRaw(
        requestParameters: UpdateCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CreativeResponseDto>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling updateCreative.',
            );
        }

        if (requestParameters.creativeId === null || requestParameters.creativeId === undefined) {
            throw new runtime.RequiredError(
                'creativeId',
                'Required parameter requestParameters.creativeId was null or undefined when calling updateCreative.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}/creatives/{creativeId}`
                    .replace(
                        `{${'strategyReportId'}}`,
                        encodeURIComponent(String(requestParameters.strategyReportId)),
                    )
                    .replace(
                        `{${'creativeId'}}`,
                        encodeURIComponent(String(requestParameters.creativeId)),
                    ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateCreativeRequestDtoToJSON(requestParameters.updateCreativeRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CreativeResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateCreative(
        requestParameters: UpdateCreativeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CreativeResponseDto> {
        const response = await this.updateCreativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTheAdFormatsForStrategyReportRaw(
        requestParameters: UpdateTheAdFormatsForStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<StrategyReportResponseDto>> {
        if (
            requestParameters.strategyReportId === null ||
            requestParameters.strategyReportId === undefined
        ) {
            throw new runtime.RequiredError(
                'strategyReportId',
                'Required parameter requestParameters.strategyReportId was null or undefined when calling updateTheAdFormatsForStrategyReport.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/plan/strategy-reports/{strategyReportId}/formats`.replace(
                    `{${'strategyReportId'}}`,
                    encodeURIComponent(String(requestParameters.strategyReportId)),
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateAdFormatsRequestDtoToJSON(requestParameters.updateAdFormatsRequestDto),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            StrategyReportResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateTheAdFormatsForStrategyReport(
        requestParameters: UpdateTheAdFormatsForStrategyReportRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<StrategyReportResponseDto> {
        const response = await this.updateTheAdFormatsForStrategyReportRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
