import { StrategyReportResponseDto } from '@api-clients/attention-data';
import { createContext, FC, ReactNode, useContext, useMemo } from 'react';

export type StrategyReportContextProps = {
    strategyReport: StrategyReportResponseDto;
    setStrategyReport: (strategyReport: StrategyReportResponseDto) => void;
};

const StrategyReportContext = createContext<StrategyReportContextProps | undefined>(undefined);

export interface StrategyReportContextProviderProps extends StrategyReportContextProps {
    children: ReactNode | ReactNode[];
}

export const StrategyReportContextProvider: FC<StrategyReportContextProviderProps> = ({
    strategyReport,
    setStrategyReport,
    children,
}) => {
    const ctxValue = useMemo(() => ({strategyReport, setStrategyReport}), [strategyReport]);

    return (
        <StrategyReportContext.Provider value={ctxValue}>
            {children}
        </StrategyReportContext.Provider>
    );
};

export const useStrategyReportContext = () => {
    const strategyReportContext = useContext(StrategyReportContext);
    if (!strategyReportContext)
        throw new Error(
            'No StrategyReportContext.Provider found when calling useStrategyReportContext.',
        );
    return strategyReportContext;
};
