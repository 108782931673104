/**
 * Checks if a number is equal to another within a given margin of error
 * @param x
 * @param y
 * @param epsilon margin of error, defaults to 0.01 (ie within 2 decimal places)
 * @returns
 */
export const isAlmostEqual = (x: number, y: number, epsilon = 0.01) => {
    return Math.abs(x - y) < epsilon;
};

export const getNumberRange = (start: number, end: number, step?: number) => {
    step = step || 1;

    let arr = [];
    for (let i = start; i <= end; i += step) {
        arr.push(i);
    }

    return arr;
};

export const errorIfEmptyOrNull = <T>(collection: Array<T> | null | undefined) => {
    if (collection === null || collection === undefined) {
        throw new Error('the collection is null or undefined');
    }
    if (collection.length === 0) {
        throw new Error('The collection is empty');
    }
};

// Math.max(...array) or Math.min(...array) will throw stack overflow if array is too big
export const getMin = (numbers: Array<number>): number => {
    errorIfEmptyOrNull(numbers);
    let min = numbers[0];
    for (let i = 1; i < numbers.length; i++) {
        if (numbers[i] < min) min = numbers[i];
    }
    return min;
};

export const getMax = (numbers: Array<number>): number => {
    errorIfEmptyOrNull(numbers);
    let max = numbers[0];
    for (let i = 1; i < numbers.length; i++) {
        if (numbers[i] > max) max = numbers[i];
    }
    return max;
};

const getFloor = (value: number, decimalPlace = 1): number => {
    if (decimalPlace < 0) {
        throw new Error('negative decimal place is not allowed');
    }
    return Math.floor(value * 10 ** decimalPlace) / 10 ** decimalPlace;
};

const getCeil = (value: number, decimalPlace = 1): number => {
    if (decimalPlace < 0) {
        throw new Error('negative decimal place is not allowed');
    }
    return Math.ceil(value * 10 ** decimalPlace) / 10 ** decimalPlace;
};

export const getFloorNearestTenth = (value: number): number => {
    return getFloor(value, 1);
};
export const getCeilNearestTenth = (value: number): number => {
    return getCeil(value, 1);
};
export const getFloorNearestTen = (value: number): number => {
    return Math.floor(value / 10) * 10;
};
export const getCeilNearestTen = (value: number): number => {
    return Math.ceil(value / 10) * 10;
};
