import { Box, Text } from '@chakra-ui/react';
import React from 'react';

export const ActiveAttentionTooltip: React.FC = () => {
    return (
        <Box p=".5rem">
            <Text>Active attention</Text>
            <Text>Mobile: Looking directly at the ad</Text>
            <Text>Desktop: Looking directly at the ad</Text>
            <Text>TV: Looking directly at the ad</Text>
        </Box>
    );
};

export const PassiveAttentionTooltip: React.FC = () => {
    return (
        <Box p=".5rem">
            <Text>Passive attention</Text>
            <Text>Mobile: Eyes on screen, not on ad</Text>
            <Text>Desktop: Eyes on screen, not on ad</Text>
            <Text>TV: In the room but not looking at the TV</Text>
        </Box>
    );
};
