/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface FormatBenchmarkResponseDto
 */
export interface FormatBenchmarkResponseDto {
    /**
     *
     * @type {string}
     * @memberof FormatBenchmarkResponseDto
     */
    formatId?: string;
    /**
     *
     * @type {string}
     * @memberof FormatBenchmarkResponseDto
     */
    formatName?: string | null;
    /**
     *
     * @type {string}
     * @memberof FormatBenchmarkResponseDto
     */
    formatCode?: string | null;
    /**
     *
     * @type {number}
     * @memberof FormatBenchmarkResponseDto
     */
    cpm?: number;
    /**
     *
     * @type {boolean}
     * @memberof FormatBenchmarkResponseDto
     */
    isSelected?: boolean;
    /**
     *
     * @type {number}
     * @memberof FormatBenchmarkResponseDto
     */
    averageActiveAttention?: number;
    /**
     *
     * @type {number}
     * @memberof FormatBenchmarkResponseDto
     */
    averagePassiveAttention?: number;
    /**
     *
     * @type {number}
     * @memberof FormatBenchmarkResponseDto
     */
    averageInactiveAttention?: number;
    /**
     *
     * @type {number}
     * @memberof FormatBenchmarkResponseDto
     */
    attentiveReachPercentage?: number;
    /**
     *
     * @type {number}
     * @memberof FormatBenchmarkResponseDto
     */
    retentionRate?: number;
}

/**
 * Check if a given object implements the FormatBenchmarkResponseDto interface.
 */
export function instanceOfFormatBenchmarkResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormatBenchmarkResponseDtoFromJSON(json: any): FormatBenchmarkResponseDto {
    return FormatBenchmarkResponseDtoFromJSONTyped(json, false);
}

export function FormatBenchmarkResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): FormatBenchmarkResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        formatId: !exists(json, 'format_id') ? undefined : json['format_id'],
        formatName: !exists(json, 'format_name') ? undefined : json['format_name'],
        formatCode: !exists(json, 'format_code') ? undefined : json['format_code'],
        cpm: !exists(json, 'cpm') ? undefined : json['cpm'],
        isSelected: !exists(json, 'is_selected') ? undefined : json['is_selected'],
        averageActiveAttention: !exists(json, 'average_active_attention')
            ? undefined
            : json['average_active_attention'],
        averagePassiveAttention: !exists(json, 'average_passive_attention')
            ? undefined
            : json['average_passive_attention'],
        averageInactiveAttention: !exists(json, 'average_inactive_attention')
            ? undefined
            : json['average_inactive_attention'],
        attentiveReachPercentage: !exists(json, 'attentive_reach_percentage')
            ? undefined
            : json['attentive_reach_percentage'],
        retentionRate: !exists(json, 'retention_rate') ? undefined : json['retention_rate'],
    };
}

export function FormatBenchmarkResponseDtoToJSON(value?: FormatBenchmarkResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        format_id: value.formatId,
        format_name: value.formatName,
        format_code: value.formatCode,
        cpm: value.cpm,
        is_selected: value.isSelected,
        average_active_attention: value.averageActiveAttention,
        average_passive_attention: value.averagePassiveAttention,
        average_inactive_attention: value.averageInactiveAttention,
        attentive_reach_percentage: value.attentiveReachPercentage,
        retention_rate: value.retentionRate,
    };
}
