import { useApiConfiguration } from '@hooks/configuration';

import { useHelper } from '@shared/utils';
import { ChannelBenchmarkResponseDto } from '@api-clients/attention-data';
import {
    GetAllBenchmarkResultsRequest,
    StrategyReportsApi,
} from '@api-clients/attention-data/apis/StrategyReportsApi';

type useGetAllBenchmarkForStrategyReportHook = () => {
    getAllBenchmarkForStrategyReport: (
        request: GetAllBenchmarkResultsRequest,
    ) => Promise<Array<ChannelBenchmarkResponseDto>>;
};

export const useGetAllBenchmarksForStrategyReport: useGetAllBenchmarkForStrategyReportHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function getAllBenchmarkForStrategyReport(request: GetAllBenchmarkResultsRequest) {
        try {
            const config = await getAttentionDataApiConfig();

            const api = new StrategyReportsApi(config);
            return await api.getAllBenchmarkResults(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { getAllBenchmarkForStrategyReport };
};
