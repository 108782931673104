import {
    CreativeResponseDto,
    StrategyReportsApi,
    UpdateCreativeRequest,
} from '@api-clients/attention-data';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

type useUpdateCreativeHook = () => {
    updateCreative: (request: UpdateCreativeRequest) => Promise<CreativeResponseDto>;
};

export const useUpdateCreative: useUpdateCreativeHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { getDisplayedErrorMessage } = useHelper();

    async function updateCreative(request: UpdateCreativeRequest) {
        try {
            const config = await getAttentionDataApiConfig();

            const api = new StrategyReportsApi(config);
            return await api.updateCreative(request);
        } catch (e: any) {
            throw new Error(await getDisplayedErrorMessage(e));
        }
    }
    return { updateCreative };
};
