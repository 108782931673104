const AIPrivacyPolicy = 'https://www.amplifiedintelligence.com.au/privacy-policy/' as const;

const attentionPLANFaq = 'https://help.amplifiedintelligence.com.au/attentionplan' as const;
const customerSupportEmail = 'plan-support@amplifiedintelligence.com.au' as const;
const customerSupportEmailUrl = `mailto:${customerSupportEmail}` as const;
const feedbackUrl = 'https://forms.gle/oaue55PbS17t3D199' as const;
const attentionPlanTermsOfService = 'https://www.amplifiedintelligence.com.au/plan-terms/' as const;
const attentionPlanPricing =
    'https://www.amplifiedintelligence.com.au/attentionplan-pricing/' as const;
const howToGuide =
    'https://www.amplifiedintelligence.com.au/wp-content/uploads/2023/08/Media-Planning-with-attentionPLAN-2023-Guide.pdf' as const;
const bookACall = 'https://meetings.hubspot.com/clayton-parks/freemium-follow-up' as const;
const demoVideo = 'https://help.amplifiedintelligence.com.au/can-you-show-me-how-it-works' as const;
const youtubeDemoVideo = 'https://www.youtube.com/embed/VE-_6I1ZhYk' as const;
const youTubeProveVideo = 'https://www.youtube.com/embed/FQ-abuSw2jc' as const;
const advancedPackageCheckoutPage =
    'https://shop.amplifiedintelligence.com.au/products/attentionplan-advanced';
const checkOutLinkPayg = 'https://shop.amplifiedintelligence.com.au/products/attentionplan-payg';
const checkOutLinkStandard =
    'https://shop.amplifiedintelligence.com.au/products/attentionplan-standard';
const checkOutLinkProfessional =
    'https://shop.amplifiedintelligence.com.au/products/attentionplan-professional';
const checkOutLinkAdvanced =
    'https://shop.amplifiedintelligence.com.au/products/attentionplan-advanced';

export const externalUrls = {
    AIPrivacyPolicy,
    attentionPLANFaq,
    customerSupportEmail,
    customerSupportEmailUrl,
    feedbackUrl,
    attentionPlanTermsOfService,
    attentionPlanPricing,
    howToGuide,
    bookACall,
    demoVideo,
    youtubeDemoVideo,
    youTubeProveVideo,
    advancedPackageCheckoutPage,
    checkOutLinkPayg,
    checkOutLinkStandard,
    checkOutLinkProfessional,
    checkOutLinkAdvanced,
};
