import {
    AgeGroup,
    AudienceAnalysisChannelBenchmarkResponseDto,
    AudienceByAgeGroupResponseDto,
    AudienceByGenderResponseDto,
    Gender,
} from '@api-clients/attention-data';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { arrayBufferToBase64, sleepAsync, waitUntilImageReady } from '@shared/utils';
import { ChartOptions } from 'chart.js';
import { attentionPlanExcelLogo } from '@assets/images';
import { colors } from '@assets/design-system/theming/colors';

export const defaultBarThickness = 25;

export const genderGraphOptions: ChartOptions<'bar'> = {
    animation: false,
    plugins: {
        legend: {
            reverse: false,
        },
    },
    responsive: true,
    interaction: { mode: 'index' },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
            title: {
                display: true,
                text: 'Seconds',
            },
        },
    },
};

export const ageGroupGraphOptions: ChartOptions<'bar'> = {
    animation: false,
    plugins: {
        legend: {
            reverse: false,
        },
    },
    responsive: true,
    interaction: { mode: 'index' },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            title: {
                display: true,
                text: 'Seconds',
            },
            stacked: true,
        },
    },
};
export const mapGendersToLabels = (genders: Array<AudienceByGenderResponseDto>) => {
    return genders.map((g) => {
        const label = (g.gender ?? '').toString();
        return label.charAt(0).toUpperCase() + label.slice(1);
    });
};

export const mapGendersToDatasets = (genders: Array<AudienceByGenderResponseDto>) => {
    return [
        {
            label: 'Active attention',
            data: genders.map((g) => g.averageActiveAttention ?? 0),
            backgroundColor: 'rgb(47, 110, 69)',
            barThickness: defaultBarThickness,
        },
        {
            label: 'Passive attention',
            data: genders.map((g) => g.averagePassiveAttention ?? 0),
            backgroundColor: 'rgb(146, 180, 158)',
            barThickness: defaultBarThickness,
        },
        {
            label: 'Non attention',
            data: genders.map((g) => g.averageInactiveAttention ?? 0),
            backgroundColor: 'rgb(230, 237, 232)',
            barThickness: defaultBarThickness,
        },
    ];
};

export const mapAgeGroupsToLabels = (ageGroups: Array<AudienceByAgeGroupResponseDto>) => {
    return ageGroups.map((g) => {
        if (!g.ageGroup) return '';
        if (g.ageGroup === AgeGroup.Group18to24) return '18-24';
        if (g.ageGroup === AgeGroup.Group25to34) return '25-34';
        if (g.ageGroup === AgeGroup.Group35to44) return '35-44';
        if (g.ageGroup === AgeGroup.Group45to54) return '45-54';
        return '55+';
    });
};

export const mapAgeGroupsToDatasets = (ageGroups: Array<AudienceByAgeGroupResponseDto>) => {
    return [
        {
            label: 'Active attention',
            data: ageGroups.map((g) => g.averageActiveAttention ?? 0),
            backgroundColor: 'rgb(47, 110, 69)',
            barThickness: defaultBarThickness,
        },
        {
            label: 'Passive attention',
            data: ageGroups.map((g) => g.averagePassiveAttention ?? 0),
            backgroundColor: 'rgb(146, 180, 158)',
            barThickness: defaultBarThickness,
        },
        {
            label: 'Non attention',
            data: ageGroups.map((g) => g.averageInactiveAttention ?? 0),
            backgroundColor: 'rgb(230, 237, 232)',
            barThickness: defaultBarThickness,
        },
    ];
};

const pngMime = 'image/png';

export const handleAudienceAnalysisSectionExport = async (
    chart: ChartJSOrUndefined<'bar', number[], unknown>,
    benchmarks: Array<AudienceAnalysisChannelBenchmarkResponseDto>,
    channelIndex: number,
) => {
    if (!chart) {
        throw new Error('Chart not available');
    }

    const { devicePixelRatio } = window;
    chart.update();
    await sleepAsync(100);
    const bench = benchmarks[channelIndex];
    const gender = (bench.byGender ?? []).filter((g) => g.gender !== Gender.Other);
    chart.data = {
        labels: mapGendersToLabels(gender ?? []),
        datasets: mapGendersToDatasets(gender ?? []),
    };
    chart.options = genderGraphOptions;
    chart.update();
    // this sleep call is important, it gives browser time to re-paint,
    // otherwise the toBase64Image() returns nothing
    await sleepAsync(100);
    const genderImageDataUrl = chart.toBase64Image(pngMime, 1);

    const ageGroup = bench.byAgeGroup;
    chart.data = {
        labels: mapAgeGroupsToLabels(ageGroup ?? []),
        datasets: mapAgeGroupsToDatasets(ageGroup ?? []),
    };
    chart.options = ageGroupGraphOptions;
    chart.update();
    await sleepAsync(100);
    const ageGroupImageDataUrl = chart.toBase64Image(pngMime, 1);

    const genderImage = new Image();
    const ageGroupImage = new Image();
    const logoRes = await fetch(attentionPlanExcelLogo);
    const logoBuffer = await logoRes.arrayBuffer();
    const logoDataUrl = `data:image/png;base64,${arrayBufferToBase64(logoBuffer)}`;
    const logo = new Image();
    await Promise.all([
        waitUntilImageReady(genderImage, genderImageDataUrl),
        waitUntilImageReady(ageGroupImage, ageGroupImageDataUrl),
        waitUntilImageReady(logo, logoDataUrl),
    ]);

    const paddingLeft = 100 * devicePixelRatio;
    const paddingRight = 100 * devicePixelRatio;
    const paddingTop = 350 * devicePixelRatio;
    const paddingBottom = 100 * devicePixelRatio;
    const logoDimension = {
        width: logo.naturalWidth * devicePixelRatio,
        height: logo.naturalHeight * devicePixelRatio,
    };
    const logoAspectRatio = logoDimension.width / logoDimension.height;
    const drawLogoWidth = devicePixelRatio * 300;
    const drawLogoOffsetY = 100 * devicePixelRatio;
    const drawLogoOffsetX = paddingLeft;
    const drawLogoHeight = drawLogoWidth / logoAspectRatio;
    const canvasElm = document.createElement('canvas');
    const imageGap = 32 * devicePixelRatio;
    canvasElm.width =
        genderImage.width + ageGroupImage.width + imageGap + paddingLeft + paddingRight;
    canvasElm.height =
        Math.max(genderImage.height, ageGroupImage.height) + paddingTop + paddingBottom;
    const ctx = canvasElm.getContext('2d');
    if (!ctx) throw new Error('Cannot get canvas context');
    ctx.save();
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvasElm.width, canvasElm.height);
    ctx.drawImage(
        logo,
        drawLogoOffsetX + ageGroupImage.width + genderImage.width + imageGap - drawLogoWidth, // align logo to the right
        drawLogoOffsetY,
        drawLogoWidth,
        drawLogoHeight,
    );
    ctx.drawImage(genderImage, paddingLeft, paddingTop);
    ctx.drawImage(ageGroupImage, paddingLeft + genderImage.width + imageGap, paddingTop);
    ctx.restore();
    ctx.save();
    // fill channel name
    ctx.font = `${20 * devicePixelRatio}px Inter`;
    ctx.fillStyle = colors.colors.gray['700'];
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    const titleX = canvasElm.width / 2;
    const titleY = paddingTop - 20;
    ctx.fillText(bench.channelName ?? 'N/A', titleX, titleY);
    ctx.restore();
    return new Promise<Blob>((resolve, reject) => {
        canvasElm.toBlob((blob) => {
            if (!blob) {
                reject(new Error('unable to get blob'));
                return;
            }
            resolve(blob);
        });
    });
};
