import { BackButton } from '@apps/attention360/atoms';
import { Center, Heading, SkeletonText, Stack, Text, VStack } from '@chakra-ui/react';
import { routing } from '@configs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface StrategyReportHeaderSectionProps {
    title: string;
    subtitle?: string;
    lastModified?: Date;
    isLoading?: boolean;
}

export const StrategyReportHeaderSection: FC<StrategyReportHeaderSectionProps> = ({
    title,
    subtitle,
    lastModified,
    isLoading,
}) => {
    const { t } = useTranslation('mediaPlans');

    return (
        <Stack direction="column" alignItems="flex-start">
            <Stack direction="row" alignItems="center">
                <BackButton route={`/${routing.campaign.root.path}`} />
                <Text fontSize="12px" fontWeight="400" color="#373D51">
                    Campaigns
                </Text>
            </Stack>
            <VStack align="left" spacing={2}>
                {isLoading ? (
                    <Center marginTop="0.5rem">
                        <SkeletonText width="15rem" noOfLines={2} />
                    </Center>
                ) : (
                    <>
                        <Heading fontSize="lg">
                            {title}
                        </Heading>
                        <Text
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            color="gray.500"
                            fontSize="md"
                        >
                            {subtitle}
                        </Text>
                    </>
                )}
            </VStack>
        </Stack>
    );
};