/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AudienceByAgeGroupResponseDto } from './AudienceByAgeGroupResponseDto';
import {
    AudienceByAgeGroupResponseDtoFromJSON,
    AudienceByAgeGroupResponseDtoFromJSONTyped,
    AudienceByAgeGroupResponseDtoToJSON,
} from './AudienceByAgeGroupResponseDto';
import type { AudienceByGenderResponseDto } from './AudienceByGenderResponseDto';
import {
    AudienceByGenderResponseDtoFromJSON,
    AudienceByGenderResponseDtoFromJSONTyped,
    AudienceByGenderResponseDtoToJSON,
} from './AudienceByGenderResponseDto';

/**
 *
 * @export
 * @interface AudienceAnalysisChannelBenchmarkResponseDto
 */
export interface AudienceAnalysisChannelBenchmarkResponseDto {
    /**
     *
     * @type {Array<AudienceByGenderResponseDto>}
     * @memberof AudienceAnalysisChannelBenchmarkResponseDto
     */
    byGender?: Array<AudienceByGenderResponseDto> | null;
    /**
     *
     * @type {Array<AudienceByAgeGroupResponseDto>}
     * @memberof AudienceAnalysisChannelBenchmarkResponseDto
     */
    byAgeGroup?: Array<AudienceByAgeGroupResponseDto> | null;
    /**
     *
     * @type {string}
     * @memberof AudienceAnalysisChannelBenchmarkResponseDto
     */
    channelId?: string;
    /**
     *
     * @type {string}
     * @memberof AudienceAnalysisChannelBenchmarkResponseDto
     */
    channelName?: string | null;
    /**
     *
     * @type {string}
     * @memberof AudienceAnalysisChannelBenchmarkResponseDto
     */
    channelCode?: string | null;
    /**
     *
     * @type {number}
     * @memberof AudienceAnalysisChannelBenchmarkResponseDto
     */
    averageActiveAttention?: number;
    /**
     *
     * @type {boolean}
     * @memberof AudienceAnalysisChannelBenchmarkResponseDto
     */
    isSelected?: boolean;
}

/**
 * Check if a given object implements the AudienceAnalysisChannelBenchmarkResponseDto interface.
 */
export function instanceOfAudienceAnalysisChannelBenchmarkResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AudienceAnalysisChannelBenchmarkResponseDtoFromJSON(
    json: any,
): AudienceAnalysisChannelBenchmarkResponseDto {
    return AudienceAnalysisChannelBenchmarkResponseDtoFromJSONTyped(json, false);
}

export function AudienceAnalysisChannelBenchmarkResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AudienceAnalysisChannelBenchmarkResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        byGender: !exists(json, 'by_gender')
            ? undefined
            : json['by_gender'] === null
            ? null
            : (json['by_gender'] as Array<any>).map(AudienceByGenderResponseDtoFromJSON),
        byAgeGroup: !exists(json, 'by_age_group')
            ? undefined
            : json['by_age_group'] === null
            ? null
            : (json['by_age_group'] as Array<any>).map(AudienceByAgeGroupResponseDtoFromJSON),
        channelId: !exists(json, 'channel_id') ? undefined : json['channel_id'],
        channelName: !exists(json, 'channel_name') ? undefined : json['channel_name'],
        channelCode: !exists(json, 'channel_code') ? undefined : json['channel_code'],
        averageActiveAttention: !exists(json, 'average_active_attention')
            ? undefined
            : json['average_active_attention'],
        isSelected: !exists(json, 'is_selected') ? undefined : json['is_selected'],
    };
}

export function AudienceAnalysisChannelBenchmarkResponseDtoToJSON(
    value?: AudienceAnalysisChannelBenchmarkResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        by_gender:
            value.byGender === undefined
                ? undefined
                : value.byGender === null
                ? null
                : (value.byGender as Array<any>).map(AudienceByGenderResponseDtoToJSON),
        by_age_group:
            value.byAgeGroup === undefined
                ? undefined
                : value.byAgeGroup === null
                ? null
                : (value.byAgeGroup as Array<any>).map(AudienceByAgeGroupResponseDtoToJSON),
        channel_id: value.channelId,
        channel_name: value.channelName,
        channel_code: value.channelCode,
        average_active_attention: value.averageActiveAttention,
        is_selected: value.isSelected,
    };
}
