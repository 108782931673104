import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

// this hook is similar to componentDidUpdate in the older class-based React
// it **DOES NOT** run when the component mounts for the first time
export const useComponentDidUpdateEffect = (
    effect: EffectCallback,
    inputs: DependencyList | undefined,
) => {
    const isMountingRef = useRef(false);

    useEffect(() => {
        isMountingRef.current = true;
    }, []);

    useEffect(() => {
        if (!isMountingRef.current) {
            effect();
        } else {
            isMountingRef.current = false;
        }
    }, inputs);
};
