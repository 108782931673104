import { CreativeResponseDto, FormatDecayResponseDto } from '@api-clients/attention-data';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    Spacer,
    Stack,
    Switch,
    Tab,
    TabIndicator,
    TabList,
    Tabs,
    Text,
    useBoolean,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { useGetAllCreatives } from '@hooks/creatives';
import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetFormatDecayForAllFormats } from '@hooks/strategyReport/useGetFormatDecayForAllFormats';
import { pdf } from '@react-pdf/renderer';
import { CreativeRecommendationSectionPdf } from '@components/pdf/CreativeRecommendationSectionPdf';
import { downloadBlob } from '@shared/utils';
import { useCustomToast } from '@hooks/toast';
import { SortOption } from '@apps/attention360/atoms/sortOption/SortOption';
import { sortIsSelected } from '@apps/attention360/pages/strategyReports/CreativeRecommendationUtil';
import { FormatDecayGraph } from '@apps/attention360/pages/strategyReports/FormatDecayGraph';
import { StrategyReportNavigation } from '@apps/attention360/atoms';
import { routing } from '@configs';
import { AddCreativeModal } from './AddCreativeModal';
import { AllFormatsView } from './AllFormatsView';
import { CreativeSummarySection } from './CreativeSummarySection';
import { ExportButton } from './ExportButton';
import { useStrategyReportContext } from './StrategyReportContextProvider';
import { selectedCreativeQueryKey } from './StrategyReportUtil';

export type CreativeRecommendationSortOptionValue =
    | 'highRetention'
    | 'lowRetention'
    | 'highActive'
    | 'highPassive';

const AllFormatsSortOptions: {
    value: CreativeRecommendationSortOptionValue;
    heading: string;
    description?: string;
    tooltip?: string;
}[] = [
        {
            value: 'highRetention',
            heading: 'High attention retention',
            description:
                'Good for storytelling and brand building. Branding can come later in the creative.',
            tooltip:
                'How well attention retains over the viewing session (0 - 1). 1 means viewers pay the same amount of attention at the end of the ad as the start.',
        },
        {
            value: 'lowRetention',
            heading: 'Low attention retention',
            description: 'Suitable for short messages. Early branding is recommended.',
            tooltip:
                'How well attention retains over the viewing session (0 - 1). 1 means viewers pay the same amount of attention at the end of the ad as the start.',
        },
    ];

const CreativeSpecificSortOptions: {
    value: CreativeRecommendationSortOptionValue;
    heading: string;
    description?: string;
    tooltip?: string;
}[] = [
        {
            value: 'highActive',
            heading: 'High active attention on branded moments',
            description: 'For all brands, essential for small brands to grow',
            tooltip:
                'When your distinctive brand assets are used in the creative, including distinguishable logo, slogan, packaging, etc.',
        },
        {
            value: 'highPassive',
            heading: 'High passive attention on branded moments',
            description: 'Recommended for big brands with distinctive assets',
            tooltip:
                'When your distinctive brand assets are used in the creative, including distinguishable logo, slogan, packaging, etc.',
        },
    ];
export const CreativeRecommendationsSection: FC = () => {
    const { strategyReport } = useStrategyReportContext();

    const [selectedSortOption, setSelectedSortOption] =
        useState<CreativeRecommendationSortOptionValue>('highRetention');
    const [selectedCreativeSortOption, setSelectedCreativeSortOption] =
        useState<CreativeRecommendationSortOptionValue>('highActive');

    const { getAllCreatives } = useGetAllCreatives();
    const [isLoadingAllCreatives, setIsLoadingAllCreatives] = useState(false);
    const [allCreatives, setAllCreatives] = useState<CreativeResponseDto[]>();
    const fetchAllCreatives = async () => {
        try {
            setIsLoadingAllCreatives(true);
            const data = await getAllCreatives({
                strategyReportId: strategyReport.strategyReportId!,
            });
            setAllCreatives(data);
        } finally {
            setIsLoadingAllCreatives(false);
        }
    };
    useEffect(() => {
        fetchAllCreatives().catch();
    }, [strategyReport]);
    const {
        isOpen: isAddCreativeModalOpen,
        onOpen: onAddCreativeModalOpen,
        onClose: onAddCreativeModalClose,
    } = useDisclosure();

    const [searchParams, setSearchParams] = useSearchParams();

    const selectedCreativeId = searchParams.get(selectedCreativeQueryKey) ?? '';
    const [showOnlySelectedFormats, setShowOnlySelectedFormats] = useBoolean(false);

    const [selectedTab, setSelectedTab] = useState<'all' | 'creative'>('all');

    const [formatDecayResponses, setFormatDecayResponses] = useState<FormatDecayResponseDto[]>();

    const { getFormatDecayForAllFormats } = useGetFormatDecayForAllFormats();
    useEffect(() => {
        setFormatDecayResponses(undefined);
        getFormatDecayForAllFormats({ strategyReportId: strategyReport.strategyReportId! }).then(
            (r) => setFormatDecayResponses(r),
        );
    }, [strategyReport]);

    const selectedCreative =
        selectedTab === 'creative'
            ? allCreatives?.find((c) => c.id === selectedCreativeId)
            : undefined;
    const { errorToast } = useCustomToast();

    const dataSrc = (formatDecayResponses ?? [])
        .filter((d) => !showOnlySelectedFormats || (d.isSelected ?? false))
        .filter((d) => {
            if (selectedCreative === undefined || selectedCreative === null) {
                // this null check is probably not necessary
                return true;
            }
            const selectedCreativeDuration = selectedCreative.duration ?? 0;
            const formatMinDuration = d.minDuration ?? 0;
            const formatMaxDuration = d.maxDuration ?? 0;
            return (
                formatMinDuration <= selectedCreativeDuration &&
                selectedCreativeDuration <= formatMaxDuration
            );
        })
        .sort(
            (a, b) =>
                sortIsSelected(a.isSelected, b.isSelected) ||
                a.adChannelName!.localeCompare(b.adChannelName!) ||
                a.adFormatName!.localeCompare(b.adFormatName!),
        )
        .map((d) => ({
            key: d.adFormatCode!,
            adChannelCode: d.adChannelCode!,
            adChannelName: d.adChannelName!,
            adFormatCode: d.adFormatCode!,
            adFormatName: d.adFormatName!,
            minDuration: d.minDuration!,
            maxDuration: d.maxDuration!,
            aaForBrandedMoments:
                // special handling when the creative is static (duration of 0 sec)
                // active attention on branded moments = format’s average active attention
                selectedCreative && selectedCreative.duration === 0
                    ? d.averageActiveAttention ?? 0
                    : d.decaySeconds?.reduce((prv, current) => {
                        if (
                            selectedCreative?.brandedMoments &&
                            selectedCreative.brandedMoments.some(
                                (b) => current.second! >= b.start! && current.second! < b.end!,
                            )
                        ) {
                            return prv + current.activeAttentionPercentage!;
                        }

                        return prv;
                    }, 0),
            // special handling when the creative is static (duration of 0 sec)
            // passive attention on branded moments = format’s average passive attention
            paForBrandedMoments:
                selectedCreative && selectedCreative.duration === 0
                    ? d.averagePassiveAttention ?? 0
                    : d.decaySeconds?.reduce((prv, current) => {
                        if (
                            selectedCreative?.brandedMoments &&
                            selectedCreative.brandedMoments.some(
                                (b) => current.second! > b.start! && current.second! <= b.end!,
                            )
                        ) {
                            return prv + current.passiveAttentionPercentage!;
                        }

                        return prv;
                    }, 0),
            retentionRate: d.retentionRate!,
            decayCurve: d.decaySeconds!.slice(0, 5),
            isSelected: d.isSelected!,
            expandedContent:
                d.decaySeconds && d.decaySeconds.length > 0 ? (
                    <FormatDecayGraph
                        decaySeconds={d.decaySeconds}
                        brandedMoments={selectedCreative?.brandedMoments ?? undefined}
                        creativeDuration={selectedCreative?.duration}
                        showBrandedMomentsLegend={selectedTab === 'creative'}
                    />
                ) : (
                    <Box
                        height="4rem"
                        bgColor="white"
                        padding="1rem"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Text color="gray.600" fontSize="sm" fontStyle="italic">
                            Attention decay chart is not yet available for this format.
                        </Text>
                    </Box>
                ),
        }))
        .sort((a, b) => {
            // Grouping by selected/unselected
            if (a.isSelected !== b.isSelected) {
                return a.isSelected ? -1 : 1;
            }

            // Sorting within groups based on the presence of decay seconds
            if (a.decayCurve.length === 0 && b.decayCurve.length > 0) {
                return 1;
            }
            if (a.decayCurve.length > 0 && b.decayCurve.length === 0) {
                return -1;
            }

            // Other sorting criteria
            if (selectedCreative) {
                if (selectedCreativeSortOption === 'highActive') {
                    return (b.aaForBrandedMoments ?? 0) - (a.aaForBrandedMoments ?? 0);
                }
                if (selectedCreativeSortOption === 'highPassive') {
                    return (b.paForBrandedMoments ?? 0) - (a.paForBrandedMoments ?? 0);
                }
            } else {
                if (selectedSortOption === 'highRetention') {
                    return b.retentionRate - a.retentionRate;
                }
                if (selectedSortOption === 'lowRetention') {
                    return a.retentionRate - b.retentionRate;
                }
            }
            return 0;
        });
    const onExport = async () => {
        if (!formatDecayResponses) {
            errorToast('Unable to export pdf', 'Something went wrong');
            return;
        }

        const blob = await pdf(
            <CreativeRecommendationSectionPdf formatDecayResponses={dataSrc} />,
        ).toBlob();
        const filename =
            selectedTab === 'all'
                ? `attentionPLAN_Creative-Recommendations_All-formats_${strategyReport.name ?? ''}`
                : `attentionPLAN_Creative-Recommendations_${selectedCreative?.name ?? ''}_${strategyReport.name ?? ''
                }.pdf`;
        downloadBlob(blob, filename);
    };

    return (
        <>
            <AddCreativeModal
                onClose={() => {
                    onAddCreativeModalClose();
                }}
                onSuccess={async (creativeId) => {
                    await fetchAllCreatives();
                    // the newly created creative will be selected by default
                    setSearchParams((params) => {
                        if (creativeId && creativeId.length > 0) {
                            params.set(selectedCreativeQueryKey, creativeId);
                        }
                        return params;
                    });
                }}
                isOpen={isAddCreativeModalOpen}
            />
            <Stack>
                <Flex width="100%">
                    <ContainerTitle
                        headingText="Creative recommendations"
                        subtitleText="We have ranked media formats based on how attention is retained over time so your creative could make the most impact"
                    />
                    <Spacer />
                    <Stack align="end">
                        <HStack>
                            <ExportButton onClick={() => onExport()} />
                        </HStack>
                        <Box>
                            <FormControl display="flex" alignItems="center">
                                <FormLabel mb="0">Show only selected formats</FormLabel>
                                <Switch
                                    isChecked={showOnlySelectedFormats}
                                    onChange={setShowOnlySelectedFormats.toggle}
                                />
                            </FormControl>
                        </Box>
                    </Stack>
                </Flex>
                {selectedTab === 'creative' && (
                    <CreativeSummarySection
                        onAddCreative={() => onAddCreativeModalOpen()}
                        creatives={allCreatives ?? []}
                        onActionSuccess={() => fetchAllCreatives()}
                        isLoadingAllCreatives={isLoadingAllCreatives}
                    />
                )}

                <Flex gap="2rem" justifyContent="space-between" alignItems="end">
                    <Flex alignItems="center" gap=".5rem">
                        {selectedCreative
                            ? CreativeSpecificSortOptions.map((o) => (
                                <SortOption
                                    key={o.value}
                                    value={o.value}
                                    heading={o.heading}
                                    description={o.description}
                                    selectedSortOption={selectedCreativeSortOption}
                                    setSelectedSortOption={setSelectedCreativeSortOption}
                                    tooltip={o.tooltip}
                                />
                            ))
                            : AllFormatsSortOptions.map((o) => (
                                <SortOption
                                    key={o.value}
                                    value={o.value}
                                    heading={o.heading}
                                    description={o.description}
                                    selectedSortOption={selectedSortOption}
                                    setSelectedSortOption={setSelectedSortOption}
                                    tooltip={o.tooltip}
                                />
                            ))}
                    </Flex>
                    <Tabs
                        colorScheme="orange"
                        onChange={(i) => setSelectedTab(i === 0 ? 'all' : 'creative')}
                    >
                        <TabList>
                            <Tab>All formats</Tab>
                            <Tab>Creative performance</Tab>
                        </TabList>
                    </Tabs>
                </Flex>
                <AllFormatsView
                    dataSource={dataSrc}
                    selectedSortOption={selectedSortOption}
                    selectedCreativeSortOption={selectedCreativeSortOption}
                    formatDecayResponses={formatDecayResponses}
                    showOnlySelectedFormats={showOnlySelectedFormats}
                    selectedCreative={selectedCreative}
                />
                {!isLoadingAllCreatives && (
                    <Box mt="1rem">
                        <StrategyReportNavigation
                            prev={`../${routing.strategyReports.view.audienceAnalysis.path}`}
                        />
                    </Box>
                )}
            </Stack>
        </>
    );
};
