import {
    CountryCodeIso2,
    CreateStrategyReportRequestDto,
    SelectedAdFormatRequestDto,
} from '@api-clients/attention-data';
import { SelectedAdFormatResponseDto } from '@api-clients/attention-data/models/SelectedAdFormatResponseDto';
import { StrategyReportResponseDto } from '@api-clients/attention-data/models/StrategyReportResponseDto';
import { AgeGroup, Gender } from '@api-clients/media-plan';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import { CountrySelect, MultiSelect } from '@apps/attentionADJUST/components/molecules';
import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Input,
    SimpleGrid,
    Stack,
    Text,
    useBoolean,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { AsyncCard } from '@components/atoms';
import { AdFormatsReportsModal } from '@components/molecules/modals/adFormatsReportsModal/AdFormatsReportsModal';
import { routing } from '@configs';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { useGetAllAdFormats } from '@hooks/adFormats/useGetAllAdFormats';
import { useGetGlobalCountries } from '@hooks/globalCountries/useGetGlobalCountries';
import { useCreateStrategyReport } from '@hooks/strategyReport';
import { useGetStrategyReports } from '@hooks/strategyReports';
import { useCustomToast } from '@hooks/toast';
import { useAppContextHelper } from '@hooks/_contexts';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { selectedGlobalCampaign } from '@redux/slices/global/campaign/selectors';
import { findCountryName } from '@shared/cores/types/Country';
import { useHelper } from '@shared/utils';
import {
    CountryFeatureCode,
    GetCountryCodeFromCountryFeature,
    isStrategyReportCountryFeature,
} from '@shared/utils/formats/countryFeatures';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const HelperText: FC<{ description: string; isItalic?: boolean }> = ({
    description,
    isItalic = false,
}) => (
    <Text
        as={isItalic ? 'i' : undefined}
        color="gray.600"
        fontSize=".875rem"
        lineHeight="1.5"
        css={{ textAlign: 'left' }}
    >
        {description}
    </Text>
);

export const StrategyReportCreationPage: FC = () => {
    const emitCreateStrategyReportEvent = usePosthogEvent('Create a strategy report');
    const navigate = useNavigate();
    const { errorToast } = useCustomToast();
    const { strategyReportId, campaignId } = useParams();
    const { formatOptionsForSelect, formatAgeGroup, formatStringToCapitalized } = useHelper();

    const [selectedGenders, setSelectedGenders] = useState<Gender[]>(Object.values(Gender));
    const [selectedAgeGroups, setSelectedAgeGroups] = useState<AgeGroup[]>(Object.values(AgeGroup));
    const [selectedCountries, setSelectedCountries] = useState<CountryCodeIso2[]>([]);
    const [isUsingGlobal, setIsUsingGlobal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useBoolean(false);
    const [isFetching, setIsFetching] = useBoolean(false);

    const [reportName, setReportName] = useState<string>('');
    const [createdStrategyReportId, setCreatedStrategyReportId] = useState<string>(
        strategyReportId || '',
    );
    const [strategyReportDetail, setStrategyReportDetail] = useState<StrategyReportResponseDto>();
    const { getStrategyReports } = useGetStrategyReports();
    const { createStrategyReport } = useCreateStrategyReport();

    const [formatsData, setFormatsData] = useState<SelectedAdFormatResponseDto[]>();
    const { getAllAdFormats } = useGetAllAdFormats();
    const { getGlobalCountries } = useGetGlobalCountries();

    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();
    const {
        isOpen: isAdFormatsModalOpen,
        onOpen: onAdFormatsModalOpen,
        onClose: onAdFormatsModalClose,
    } = useDisclosure();

    useEffect(() => {
        const fetchAdFormats = async () => {
            const allAdFormats = await getAllAdFormats();
            setFormatsData(
                allAdFormats.map(
                    (f): SelectedAdFormatResponseDto => ({
                        adFormatCode: f.adFormatCode,
                        adFormatName: f.adFormatName,
                        adChannelName: f.channelName,
                        adChannelCode: f.channelCode,
                        isChecked: true,
                        cpm: 0,
                    }),
                ),
            );
            setSelectedCountries([]);
        };
        fetchAdFormats();
    }, []);

    const { canUseGlobal, subscribedCountries } = useMemo(() => {
        const isGlobalFeatureBalance = featureBalances?.some(
            (f) =>
                f.featureCode === FeatureCode.StrategyReportAccessCountryGlobal &&
                f.limitType === LimitType.Unlimited,
        );
        setIsUsingGlobal(!!isGlobalFeatureBalance);
        return {
            canUseGlobal: isGlobalFeatureBalance,
            subscribedCountries:
                featureBalances
                    ?.filter(
                        (f) =>
                            isStrategyReportCountryFeature(f.featureCode!) &&
                            f.limitType === LimitType.Unlimited,
                    )
                    .map(
                        (f) =>
                            GetCountryCodeFromCountryFeature(
                                f.featureCode as CountryFeatureCode,
                            ) as CountryCodeIso2,
                    ) ?? [],
        };
    }, [featureBalances]);

    const handleClickOnGenerateStrategyReport = async () => {
        const payload: CreateStrategyReportRequestDto = {
            campaignId: campaignId!,
            name: reportName,
            selectedCountryCodes: [...selectedCountries],
            selectedAgeGroups,
            selectedGenders,
            selectedFormatCodes: formatsData?.map(
                (f): SelectedAdFormatRequestDto => ({
                    adFormatCode: f.adFormatCode,
                    cpm: f.cpm,
                    isChecked: f.isChecked,
                }),
            ),
            isUsingGlobalCountry: isUsingGlobal,
        };

        try {
            setIsLoading.on();
            const response = await createStrategyReport(payload);
            setCreatedStrategyReportId(response.strategyReportId || '');
            navigate(
                `/${routing.campaign.root.path}/${campaignId}/${routing.strategyReports.root.path}/${routing.strategyReports.view.path}/${response.strategyReportId}`,
            );
        } catch (e) {
            errorToast(
                'Unable to create strategy report',
                'Strategy report exists for this campaign',
            );
        } finally {
            setIsLoading.off();
        }
    };

    useEffect(() => {
        const fetchStrategyReport = async (id: string) => {
            setIsFetching.on();
            try {
                const report = await getStrategyReports({ strategyReportId: id });
                setStrategyReportDetail(report);
                setReportName(report.name || '');
            } catch (e) {
                errorToast('Unable to fetch strategy report', 'Strategy report does not exist');
            } finally {
                setIsFetching.off();
            }
        };

        if (strategyReportId) {
            fetchStrategyReport(strategyReportId);
        }
    }, [strategyReportId]);

    const toast = useToast();

    const selectedGlobalCampaignSelector = useSelector(selectedGlobalCampaign);

    const {
        helper: { selectGlobalCampaign },
    } = useAppContextHelper();

    useEffect(() => {
        if (!campaignId) {
            return;
        }

        selectGlobalCampaign(campaignId).catch();
    }, [campaignId]);

    useEffect(() => {
        if (selectedGlobalCampaignSelector) {
            setReportName(selectedGlobalCampaignSelector.name);
        }
    }, [selectedGlobalCampaignSelector]);

    return (
        <Container>
            <Stack>
                <ContainerTitle
                    headingText="Create strategy report"
                    subtitleText="All the information below will be used to calculate attention data and generate
                    recommendations for your campaign"
                />
                <AsyncCard>
                    <Stack>
                        <SimpleGrid columns={3} spacing=".5rem">
                            <FormControl>
                                <FormLabel>Strategy report name</FormLabel>
                                <Input
                                    placeholder={reportName}
                                    onChange={(e) => setReportName(e.target.value)}
                                    name="reportName"
                                    value={reportName}
                                />
                            </FormControl>
                        </SimpleGrid>

                        <Flex flexDir="column" gap=".5rem">
                            <SimpleGrid columns={3} spacing=".5rem">
                                <CountrySelect
                                    label="Countries"
                                    setSelectedOptions={(selectedOptions) =>
                                        setSelectedCountries(
                                            selectedOptions.map((o) => o.value as CountryCodeIso2),
                                        )
                                    }
                                    selectedOptions={formatOptionsForSelect(
                                        selectedCountries,
                                        findCountryName,
                                    )}
                                    isRequired
                                    canSelectGlobal={!!canUseGlobal}
                                    isUsingGlobal={!!isUsingGlobal}
                                    setIsUsingGlobal={() => setIsUsingGlobal(!isUsingGlobal)}
                                    availableCountryOptions={subscribedCountries}
                                />
                                <MultiSelect
                                    label="Age group"
                                    options={formatOptionsForSelect(
                                        Object.values(AgeGroup),
                                        formatAgeGroup,
                                    ).map((o) => ({
                                        isDisabled: false,
                                        showLockIcon: false,
                                        ...o,
                                    }))}
                                    setSelectedOptions={(selectedOptions) =>
                                        setSelectedAgeGroups(
                                            selectedOptions.map((o) => o.value as AgeGroup),
                                        )
                                    }
                                    selectedOptions={formatOptionsForSelect(
                                        selectedAgeGroups,
                                        formatAgeGroup,
                                    )}
                                    isRequired
                                    isClearable
                                    selectAll
                                    allowSelectAll
                                />
                                <MultiSelect
                                    label="Gender"
                                    options={formatOptionsForSelect(
                                        Object.values(Gender),
                                        formatStringToCapitalized,
                                    )}
                                    setSelectedOptions={(selectedOptions) =>
                                        setSelectedGenders(
                                            selectedOptions.map((o) => o.value as Gender),
                                        )
                                    }
                                    selectedOptions={formatOptionsForSelect(
                                        selectedGenders,
                                        formatStringToCapitalized,
                                    )}
                                    isRequired
                                    isClearable
                                    selectAll
                                    allowSelectAll
                                />
                            </SimpleGrid>
                            <HelperText description="All attention data will be calculated for your selected target audience. Demographics can't be changed after creating a strategy report." />
                        </Flex>

                        <Flex flexDir="column" gap=".5rem">
                            <Box>
                                <FormLabel>Formats</FormLabel>
                                <SimpleGrid columns={3} spacing=".5rem">
                                    <Button
                                        variant="outline"
                                        onClick={onAdFormatsModalOpen}
                                        rightIcon={<Icon as={AdjustmentsHorizontalIcon} />}
                                        colorScheme="orange"
                                        w="100%"
                                        fontSize="1rem"
                                    >
                                        Configure formats
                                    </Button>
                                </SimpleGrid>
                            </Box>
                            <HelperText
                                description="Selected channels and formats' performance will be viewed
                                    against all of our available channels and formats. Formats and
                                    CPMs can be updated after creating a strategy report."
                            />
                        </Flex>
                        {isLoading && (
                            <HelperText
                                isItalic
                                description="Generating your strategy report — this may take up to 1 minute."
                            />
                        )}

                        <SimpleGrid columns={3} spacing=".5rem">
                            <Flex gap=".75rem" alignItems="center" w="100%">
                                <Button
                                    variant="outline"
                                    fontSize="1rem"
                                    padding="0.625rem 1.25rem"
                                    onClick={() => {}}
                                    flex="1 1 auto"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    flex="3 3 auto"
                                    onClick={() => {
                                        emitCreateStrategyReportEvent();
                                        handleClickOnGenerateStrategyReport().catch();
                                    }}
                                    fontSize="1rem"
                                    padding="0.625rem 1.25rem"
                                    isLoading={isLoading}
                                    loadingText="Generating..."
                                    isDisabled={
                                        selectedAgeGroups.length === 0 ||
                                        selectedGenders.length === 0 ||
                                        (selectedCountries.length === 0 && !isUsingGlobal) ||
                                        reportName.trim() === ''
                                    }
                                >
                                    Generate strategy report
                                </Button>
                            </Flex>
                        </SimpleGrid>
                    </Stack>
                </AsyncCard>

                {formatsData && (
                    <AdFormatsReportsModal
                        selectedAdFormats={formatsData}
                        onConfirm={(adFormats) => {
                            setFormatsData(adFormats);
                            onAdFormatsModalClose();
                            toast({
                                title: `Ad formats changed`,
                                description: `Ad formats modified`,
                                status: 'success',
                                duration: 3000,
                                isClosable: true,
                            });
                        }}
                        onCancel={onAdFormatsModalClose}
                        isOpen={isAdFormatsModalOpen}
                    />
                )}
            </Stack>
        </Container>
    );
};
